<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Campaign List</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="d-flex align-items-center">
          <b-button
            size="sm"
            class="mr-4"
            v-b-toggle.collapse-advancedFilter
            :disabled="advancedFilter_visible"
          >
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button>
          <view-lang-select v-model="view_lang" :options="languages" />
        </b-col>
        <b-col class="text-lg-right">
          <b-button
            size="sm"
            v-b-toggle.collapse-newCampaign
            :disabled="newCampaign_visible"
          >
            <b-icon icon="plus" aria-hidden="true"></b-icon> New campaign
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-collapse
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-select
              v-model="campaignsTypeListSelected"
              :options="campaignsTypeList"
            ></b-form-select>
          </b-col>

          <b-col>
            <b-form-select
              v-model="campaignsStatusListSelected"
              :options="campaignsStatusList"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-form-checkbox-group
              v-model="campaignsCheckboxListSelected"
              :options="campaignsCheckboxList"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-collapse id="collapse-newCampaign" v-model="newCampaign_visible">
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Add a new campaign</span>
          <b-button-close v-b-toggle.collapse-newCampaign class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <BoxOptions
              class="mt-2"
              v-model="campaignsTypeBoxListSelected"
              :options="campaignsTypeBoxList"
              @input="redirectNewCampaign"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-overlay
      :show="items == null || showOverlay"
      rounded="sm"
      class="overlay-placeholder"
    >
      <b-card>
        <b-table
          hover
          :items="campaignList"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(title)="row">
            {{ row.item.title[view_lang] }}
          </template>
          <template #cell(debug)="row">
            <!-- COLONNA DEBUG CON CONTROLLO PER DISABILITARE -->
            <b-form-checkbox
              :disabled="!row.item.debug"
              switch
              v-model="row.item.debug"
              @change="updateDebug(row.item)"
            ></b-form-checkbox>

            <!-- COLONNA DEBUG SENZA CONTROLLO -->
            <!-- <b-form-checkbox
              switch
              v-model="row.item.debug"
              @change="updateDebug(row.item)"
            ></b-form-checkbox> -->
          </template>
          <template #cell(attiva)="row">
            <b-form-checkbox
              :disabled="row.item.debug"
              switch
              v-model="row.item.attiva"
              @change="updateState(row.item)"
            ></b-form-checkbox>
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                :to="getPath(row.item.id_tipo_campagna).result + row.item.id"
                exact
                >Campaign results</b-dropdown-item
              >
              <!-- <b-dropdown-item
                v-if="row.item.id_tipo_campagna == 2"
                :to="'/UpdateKpis/' + row.item.id"
                >Update Kpis</b-dropdown-item
              > -->
              <b-dropdown-item
                :to="getPath(row.item.id_tipo_campagna).base + row.item.id"
                >Edit</b-dropdown-item
              >
              <b-dropdown-item @click="duplicateCampaign(row.item.id)"
                >Duplicate</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  currentItem = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>

    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="currentItem = {}"
      @ok="deleteItem(currentItem.id)"
      >Do you really want to delete "{{
        currentItem.title
          ? currentItem.title[view_lang]
          : " the selected element"
      }}"?</b-modal
    >
    <b-modal
      v-model="showModal"
      title="Select Company where the campaign will be duplicated"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="currentCampaign = 0"
      @ok="confirmDuplicateCampaign(currentCampaign)"
    >
      <b-form-select
        v-model="selectedCompany"
        :options="companyListFromStore"
      ></b-form-select>
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService";
import { format } from "date-fns";
import compareAsc from "date-fns/compareAsc";
import { mapGetters } from "vuex";
import { SET_CURRENT_COMPANY } from "../store/actions/config";
export default {
  components: {},
  data() {
    return {
      currentCampaign: 0,
      showModal: false,
      selectedCompany: null,
      showOverlay: false,
      items: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "title", label: "Title" },
        { key: "campagna_type_name", label: "Type" },
        { key: "company_name", label: "Company" },
        { key: "data_inizio_view", label: "Start" },
        { key: "data_fine_view", label: "End" },
        { key: "debug", label: "Debug" },
        { key: "attiva", label: "Live" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,
      newCampaign_visible: false,
      campaignsTypeList: [
        { value: null, text: "All campaigns" },
        { value: "Campagna", text: "Campaign" },
        { value: "On boarding", text: "On boarding" },
      ],
      campaignsTypeListSelected: null,
      campaignsCompanyList: [],
      campaignsCompanyListSelected: null,
      campaignsStatusList: [
        { value: null, text: "All Status" },
        { value: true, text: "Online" },
        { value: false, text: "Offline" },
      ],
      campaignsStatusListSelected: null,
      campaignsCheckboxList: [
        { value: "ended", text: "Ended" },
        { value: "uncoming", text: "Uncoming" },
        { value: "rightnow", text: "Right now" },
      ],
      campaignsCheckboxListSelected: [],
      campaignsTypeBoxList: [
        { value: "campaign", text: "Campaign", icon: "pen" },
        { value: "onboarding", text: "On boarding", icon: "pen" },
        { value: "dataVisualization", text: "Data Visualization", icon: "pen" },
      ],
      campaignsTypeBoxListSelected: null,

      showDeleteModal: false,
      currentItem: {},

      view_lang: null,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("Campaign");
    this.view_lang = this.default_lang.value || this.default_lang;
  },
  async mounted() {
    this.getCapmpaigns();
    await this.service.getOptions("Company").then((data) => {
      this.campaignsCompanyList = [
        { text: "All Companies", value: null },
        ...data,
      ];
    });
    this.campaignsCompanyListSelected = this.$store.getters.currentCompany;
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
    companyListFromStore() {
      return this.$store.getters.companyList;
    },
    campaignList() {
      if (!this.items) {
        return [];
      }
      return this.items.filter((item) => {
        if (
          this.campaignsTypeListSelected &&
          item.campagna_type_name != this.campaignsTypeListSelected
        )
          return false;
        if (
          this.campaignsCompanyListSelected &&
          item.id_company != this.campaignsCompanyListSelected
        )
          return false;
        if (
          this.campaignsUserListSelected &&
          item.user != this.campaignsUserListSelected
        )
          return false;
        if (
          this.campaignsStatusListSelected != null &&
          item.attiva != this.campaignsStatusListSelected
        )
          return false;
        if (
          this.campaignsCheckboxListSelected == "ended" &&
          compareAsc(new Date(), new Date(item.date_end)) != 1
        )
          return false;
        if (
          this.campaignsCheckboxListSelected == "uncoming" &&
          compareAsc(new Date(), new Date(item.date_start)) != -1
        )
          return false;
        if (this.campaignsCheckboxListSelected == "rightnow") {
          if (
            compareAsc(new Date(), new Date(item.date_start)) == -1 ||
            compareAsc(new Date(), new Date(item.date_end)) == 1
          )
            return false;
        }
        return true;
      });
    },
    totalRows() {
      return this.items ? this.items.length : 0;
    },
  },
  methods: {
    getCapmpaigns() {
      this.service.read().then((data) => {
        this.items = data;
        this.items.forEach((element) => {
          let dayStart = new Date(element.data_inizio);
          let dayEnd = new Date(element.data_fine);
          element.date_start = dayStart;
          element.date_end = dayEnd;
          element.data_inizio_view = format(dayStart, "dd MM yyyy");
          element.data_fine_view = format(dayEnd, "dd MM yyyy");
          // element.disableDebug = false;
        });
        this.showOverlay = false;
      });
    },
    duplicateCampaign(item) {
      console.log("DUP", item, this.currentCompanyFromStore);
      this.showModal = true;
      this.currentCampaign = item;
      this.selectedCompany = this.currentCompanyFromStore;
    },
    confirmDuplicateCampaign() {
      this.showOverlay = true;
      this.service
        .readCustomEndpoint(
          `CampaignManager/DuplicateCampaign/${this.currentCampaign}/${this.selectedCompany}`
        )
        .then((res) => {
          if (res) {
            this.getCapmpaigns();
          } else {
            console.log("ERRORE nella duplicazione della campagna");
            this.showOverlay = false;
          }
        });
      this.items.splice(0);
      this.service.read().then((data) => {
        this.items = data;
        this.items.forEach((element) => {
          let dayStart = new Date(element.data_inizio);
          let dayEnd = new Date(element.data_fine);
          element.date_start = dayStart;
          element.date_end = dayEnd;
          element.data_inizio_view = format(dayStart, "dd MM yyyy");
          element.data_fine_view = format(dayEnd, "dd MM yyyy");
        });
      });
    },
    redirectNewCampaign(value) {
      if (value == "campaign") this.$router.push({ name: "New Campaign" });
      if (value == "onboarding") this.$router.push({ name: "New On Boarding" });
      if (value == "dataVisualization")
        this.$router.push({ name: "New Data Visualization" });
    },
    getPath(type) {
      if (type == 2) {
        return {
          result: "/CampaignResults/",
          base: "/Campaign/",
        };
      }
      if (type == 1) {
        return {
          result: "/CampaignResults/",
          base: "/Onboarding/",
        };
        //   return {
        //     result: "/OnboardingResults/",
        //     base: "/Onboarding/",
        //   };
      }

      if (type == 3) {
        return {
          result: "/CampaignResults/",
          base: "/dataVisualization/",
        };
        //   return {
        //     result: "/OnboardingResults/",
        //     base: "/Onboarding/",
        //   };
      }
      return {};
    },
    updateDebug(item) {
      console.log("UPDATE DEBUG (ITEM)", item);

      if (item.debug == false) {
        item.disableDebug = true;
        console.log("item.debug == false");
        this.service
          .postCustomEndpoint("CampaignManager/ResetCampaign/" + item.id)
          .then((res) => {
            console.log("CampaignManager/ResetCampaign/", res);
          });
      }
    },
    updateState(item) {
      this.service.update(item).then((response) => {
        if (response.id == item.id) {
          this.$successToast();
        } else {
          this.$errorToast();
          item.attiva = !item.attiva;
          return false;
        }
      });
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    campaignsCompanyListSelected: {
      handler() {
        this.$store.dispatch(
          SET_CURRENT_COMPANY,
          this.campaignsCompanyListSelected
        );
      },
    },
    currentCompanyFromStore: {
      handler() {
        this.campaignsCompanyListSelected = this.currentCompanyFromStore;
      },
    },
  },
};
</script>
